import React, { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import clsx from "clsx";
import { Link } from 'gatsby';
import Button from "src/components/common/Button/Button";

import BlueBackgroundContent from "./backgrounds/BlueBackgroundContent";
import RedBackgroundContent from "./backgrounds/RedBackgroundContent";
import YellowBackgroundContent from "./backgrounds/YellowBackgroundContent";
import PurpleBackgroundContent from "./backgrounds/PurpleBackgroundContent";

import * as Animation from "./Animation.module.scss";
import * as styles from "./Carousel.module.scss";
import useWindowSize from "src/components/hooks/useWindowSize";
import MobileCarousel from "./MobileCarousel";


const ThemeColors = {
  red: {
    color: "#EA5045",
    header: "Przyspiesz onboarding i offboarding",
    text: "Zautomatyzuj powtarzalne czynności administracyjne i skróć czas potrzebny na wdrożenie pracownika lub pożegnanie się z nim.",
    link: "/product/onboarding-offboarding",
    backgroundContent: <RedBackgroundContent />,
  },
  purple: {
    color: "#287ABE",
    header: "Zbuduj nową kulturę komunikacji",
    text: "Ogranicz przekazywanie informacji z ust do ust lub przy pomocy tablicy ogłoszeń. Stwórz nową jakość komunikacji na linii biuro-pracownik.",
    link: "/product/communication",
    backgroundContent: <BlueBackgroundContent />,
  },
  yellow: {
    color: "#F6CE48",
    header: "Deleguj zadania szybko i pewnie",
    text: "Zarządzaj rozproszonymi pracownikami i oddziałami. Miej pewność, że komunikat dotrze do właściwej osoby, we właściwym czasie.",
    link: "/product/organizer",
    backgroundContent: <YellowBackgroundContent />,
  },
  blue: {
    color: "#75529D",
    header: "Przyciągnij nowych pracowników i klientów",
    text: "Wykorzystaj nowoczesną platformę do komunikacji jako wyróżnik firmowy. Przyciągnij do siebie swoją nowoczesnością!",
    link: "/product/employer-branding",
    backgroundContent: <PurpleBackgroundContent />,
  },
};

const ThemeColorsArray = Object.values(ThemeColors);

const Article = ({ index, color, isVisible, text, header, link }) => {
  return (
    <CSSTransition
      in={isVisible}
      timeout={{ enter: 550, exit: 250 }}
      classNames={Animation}
      mountOnEnter
      unmountOnExit
    >
      <article>
        <h3 style={{ fontSize: "3.2rem" }}>{header}</h3>
        <p>{text}</p>
        <Link className={styles.button} to={link}><Button>Zobacz więcej</Button></Link>
      </article>
    </CSSTransition>
  );
};

const Background = ({
  color,
  isFirstElement = false,
  isLastElement = false,
  setSection = () => { },
  children = "",
  index,
}) => {
  const sectionRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (!sectionRef?.current || !contentRef.current) {
      return;
    }

    function goToSection(section, color) {
      setSection();
      gsap.to(".animated-background-color", {
        backgroundColor: color,
      });
    }

    const section = sectionRef.current;
    const setSectionTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        onEnter: () => goToSection(section, color),
        onEnterBack: () => goToSection(section, color),
        start: "top 50%",
        end: "bottom 50%",
      },
    });

    const animationEnter = !isFirstElement
      ? gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            scrub: true,
            start: "10% 90%",
            end: "50% 50%",
          },
        })
        .from(contentRef.current, { opacity: 0, delay: 0.2, duration: 1 })
      : null;

    const animationLeave = !isLastElement
      ? gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            scrub: true,
            start: "52% 50%",
            end: "80% 50%",
          },
        })
        .to(contentRef.current, { opacity: 0 })
      : null;

    return () => {
      setSectionTrigger?.kill();
      animationEnter?.kill();
      animationLeave?.kill();
    };
  }, [color, isFirstElement, isLastElement]);

  return (
    <div ref={sectionRef} className={styles.FakeBackgroundContainer}>
      <div
        ref={contentRef}
        style={{ display: "flex", width: "100%", height: "100%" }}
      >
        {children}
      </div>
    </div>
  );
};

function Carousel() {
  const [activeSection, setActiveSection] = useState(ThemeColors.red.color);
  const [isAbsolute, setIsAbsolute] = useState(true);
  const [position, setPosition] = useState({ top: 0, bottom: "initial" });
  const sectionRef = useRef();

  useEffect(() => {
    if (!sectionRef.current) {
      return;
    }
    let absoluteToFixed;
    ScrollTrigger.matchMedia({
      "(min-width: 1043px)": function setCarouselPosition() {
        absoluteToFixed = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 0.5%",
            end: "bottom 99.5%",
            onEnter: () => setIsAbsolute(false),
            onLeaveBack: () => {
              setIsAbsolute(true);
              setPosition({ bottom: "initial", top: 0 });
            },
            onEnterBack: () => setIsAbsolute(false),
            onLeave: () => {
              setIsAbsolute(true);
              setPosition({ bottom: 0, top: "initial" });
            },
          },
        });
      },
    });

    return () => {
      absoluteToFixed?.kill();
    };
  }, []);

  useEffect(() => {
    if (document) {
      document
        .querySelector("html")
        .style.setProperty(
          "scroll-snap-type",
          isAbsolute ? "initial" : "y mandatory"
        );
    }
  }, [isAbsolute]);

  return (
    <section
      ref={sectionRef}
      className={clsx(styles.CarouselWrapper, "animated-background-color")}
    >
      <div
        className={
          isAbsolute ? styles.AbsoluteContainer : styles.StickyContainer
        }
        style={position}
      >
        <div className={styles.ImageWrapper}></div>
        <div className={styles.TextWrapper}>
          <TransitionGroup component={null}>
            {ThemeColorsArray.map(
              ({ color, header, text, link, backgroundContent }, index) => (
                <Article
                  key={`Animated_carousel_${header}`}
                  text={text}
                  header={header}
                  link={link}
                  index={index}
                  color={color}
                  isVisible={color === activeSection}
                  images={backgroundContent}
                />
              )
            )}
          </TransitionGroup>
        </div>
      </div>
      <div>
        {ThemeColorsArray.map(({ color, backgroundContent }, index) => (
          <Background
            key={`background_animation_${color}`}
            color={color}
            index={index}
            isLastElement={index + 1 === ThemeColorsArray.length}
            isFirstElement={index === 0}
            setSection={() => setActiveSection(color)}
          >
            {backgroundContent}
          </Background>
        ))}
      </div>
    </section>
  );
}

const CarouselWrapper = () => {
  const { width } = useWindowSize();
  return (
    <>
      <MobileCarousel content={ThemeColorsArray} />
      {width >= 1043 && <Carousel />}
    </>
  );
};

export default CarouselWrapper;
