import * as React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import Paragraph from "./sections/paragraph/Paragraph";
import Carousel from "./sections/carousel/Carousel";
import NewHero from "./sections/NewHero/NewHero";
import Header from "src/components/layout/Header/Header";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";
import Clients from "src/components/layout/Clients/Clients";

const Index = () => {
  return (
    <Layout>
      <Seo />
      <main>
        <Header>
          <NewHero pageName="main" />
          <Paragraph
            title="Komunikuj się na miarę XXI wieku"
            desc="Zastąp część tradycyjnej komunikacji nowoczesną platformą mobilną. Zapanuj nad chaosem informacyjnym, który wynika z “głuchego telefonu” w firmie."
          />
        </Header>
        <Clients />
        <Carousel />
      </main>
      <ContactFooter showForm={true} showInfo={true} />
      <Patrons />
    </Layout>
  );
};

export default Index;
