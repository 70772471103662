import React from "react";
import Button from "src/components/common/Button/Button";
import { Link } from 'gatsby';
import * as styles from "./MobileCarousel.module.scss";

export default function MobileCarousel({ content }) {
  return (
    <section className={styles.Section}>
      {content.map(({ header, color, backgroundContent, text, link }) => (
        <article
          key={`Mobile_carousel_${header}`}
          style={{ backgroundColor: color }}
        >
          <div>{backgroundContent}</div>
          <div className={styles.Content}>
            <h2>{header}</h2>

            <p>{text}</p>
            <Link className={styles.button} to={link}><Button >Zobacz więcej</Button></Link>

          </div>

        </article>
      ))}
    </section>
  );
}
