import * as React from "react";
import * as styles from "./Clients.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const Clients = () => {
  return (
    <div className={styles.Clients}>
      <h1>Zaufali nam już</h1>
      {/* <p>Z Hexar korzystają codziennie tysiące pracowników w całej Polsce.</p> */}
      <div className={styles.ImgWrapper}>
        <StaticImage
          src="../../../images/wieltonlogo.png"
          alt="Wielton logo"
          className={styles.GatsbyImageWrapper}
        />
        <StaticImage
          src="../../../images/dinologo.png"
          alt="Dino logo"
          className={styles.GatsbyImageWrapper}
        />
      </div>
    </div>
  );
};

export default Clients;
