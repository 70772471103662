import React from "react";
import clsx from "clsx";
import * as styles from "./VerticalBackgroundContent.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import useWindowSize from "src/components/hooks/useWindowSize";

export const query = graphql`
{
  img1: file(relativePath: {eq: "yellowSection1.png"}){
    childImageSharp{
      gatsbyImageData
    }
  }
  
    img2:file(relativePath: { eq: "yellowSection2.png" }){
      childImageSharp{
        gatsbyImageData
      }
    }
    img3:file(relativePath: { eq: "yellowSection1-small.png" }){
      childImageSharp{
        gatsbyImageData
      }
    }
    img4:file(relativePath: { eq: "yellowSection2-small.png" }){
      childImageSharp{
        gatsbyImageData
      }
    }
  
}`

export default function YellowBackgroundContent() {
  const data = useStaticQuery(query)
  const width = useWindowSize()

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ImagesContainer}>
        <div className={clsx(styles.Image, styles.Top)}>
          {width && width >= 1043 ? <GatsbyImage image={data.img1.childImageSharp.gatsbyImageData} alt="app screen" style={{ overflow: "visible" }} imgStyle={{ willChange: "auto" }} />
            : <GatsbyImage image={data.img3.childImageSharp.gatsbyImageData} alt="app screen" style={{ overflow: "visible" }} imgStyle={{ willChange: "auto" }} />}          </div>
        <div className={clsx(styles.Image, styles.Bottom)}>
          {width && width >= 1043 ? <GatsbyImage image={data.img2.childImageSharp.gatsbyImageData} alt="app screen" style={{ overflow: "visible" }} imgStyle={{ willChange: "auto" }} />

            :
            <GatsbyImage image={data.img4.childImageSharp.gatsbyImageData} alt="app screen" style={{ overflow: "visible" }} imgStyle={{ willChange: "auto" }} />
          }          </div>
      </div>
    </div>
  );
}


